import React from 'react'
import '../styles/SectionTitle.scss'

const SectionTitle = ({ title, maxWidth }) => {
  return (
    <h2 className="section_title" style={{ maxWidth }}>
      {title}
    </h2>
  )
}

export default SectionTitle
