import React from 'react'
import '../styles/ModalHeader.scss'

const ModalHeader = ({ title, maxWidth, isGreenLine, text, maxWidthText }) => {
  return (
    <>
      <h2
        className={isGreenLine ? 'modal_title with_green' : 'modal_title'}
        style={{ maxWidth }}
      >
        {title}
      </h2>
      <p style={{ maxWidth: maxWidthText }} className="modal_text">
        {text}
      </p>
    </>
  )
}

export default ModalHeader
