import React from 'react'
import Header from './header'
import '../styles/MainSection.scss'
import timer from '../images/timer.png'
import Form from './Form'
import Accordion from './Accordion'
import video from '../images/main.mp4'
import preview from '../images/preview.png'

const faq = [
  {
    title: 'Заказать услуги можно дистанционно',
    text: 'Оформить договор можно не приходя в офис',
  },
  {
    title: 'В 70% случаев услуги бесплатны',
    text: 'Детальный осмотр авто будет основанием для аргументированного торга',
  },
  {
    title: 'На весь процесс от Вас потребуется не более 2х часов времени',
    text: 'Эксперт проанализирует рынок, подберет и осмотрит интересующие авто',
  },
]

const MainSection = ({ navigation }) => {
  return (
    <section className="main" id="main">
      <div className="container">
        <Header navigation={navigation} />
        <video loop muted autoPlay poster={preview}>
          <source src={video} type="video/mp4" />
        </video>
        <main>
          <div className="main_info">
            <div>
              <h1>ПОКУПАЕТЕ ПОДЕРЖАННЫЙ автомобиль?</h1>
              <div className="economy_wrapper">
                <div className="economy_time">
                  <img src={timer} alt="" />
                  Сэкономьте время
                </div>
              </div>
              <h3>
                Подберем для вас лучший автомобиль в Украине, с юридическими
                гарантиями и гарантией технической исправности.
              </h3>
            </div>
            <div className="form_wrapper mob">
              <Form
                isName
                buttonColor="green"
                sentText="Первый шаг к авто мечты - сделан"
                image="green"
                loaderColor="#43a246"
                buttonId="form1_consult"
              />
            </div>
            <div className="accordion_wrapper">
              <Accordion close="plus" faq={faq} />
            </div>
          </div>
          <div className="form_wrapper">
            <Form
              isName
              buttonColor="green"
              sentText="Первый шаг к авто мечты - сделан"
              image="green"
              loaderColor="#43a246"
              buttonText="Получить консультацию"
            />
          </div>
        </main>
      </div>
    </section>
  )
}

export default MainSection
