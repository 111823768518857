import React from 'react'
import leftArrow from '../images/left-white-arrow.png'
import rightArrow from '../images/right-white-arrow.png'

const SampleArrow = ({ clazz, onClick, side }) => {
  return (
    <div
      className={side === 'left' ? `${clazz} left` : `${clazz} right`}
      onClick={onClick}
    >
      <img src={side === 'left' ? leftArrow : rightArrow} alt="" />
    </div>
  )
}

export default SampleArrow
