import React from 'react'
import '../styles/CarSearch.scss'
import SectionTitle from './SectionTitle'
import rst from '../images/car-search-sourses/1.png'
import peoples from '../images/car-search-sourses/2.png'
import car from '../images/car-search-sourses/3.png'
import olx from '../images/car-search-sourses/4.png'
import search from '../images/search.png'

const CarSearchSection = () => {
  const sourses = [
    { image: rst, text: 'RST' },
    { image: peoples, text: 'Закрытые каналы объявлений в TELEGRAM и VIBER' },
    {
      image: car,
      text: 'Автоплощадки, которые не размещают объявления в интернете',
    },
    { image: olx, text: 'OLX' },
  ]
  return (
    <section className="car_search">
      <div className="container">
        <SectionTitle
          title="Мы ищем авто не только на AUTO.RIA"
          maxWidth="411px"
        />
        <img src={search} className="search_img" alt="" />
        <h2 className="car_search_subtitle">Но и на других каналах</h2>
        <div className="sourses">
          {sourses.map((item, index) => (
            <div className="source" key={index}>
              <div className="source_image">
                <img src={item.image} alt="" />
              </div>
              <h3>{item.text}</h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default CarSearchSection
