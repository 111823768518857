import React, { useState, useRef, useEffect } from 'react'
import '../styles/Services.scss'
import SectionTitle from './SectionTitle'
import Modal from './Modal'
import ModalForm from './ModalForm'
import ModalHeader from './ModalHeader'
import woman from '../images/woman.png'
import Loader from 'react-loader-spinner'
import axios from 'axios'
import IMask from 'imask'

const ServicesSection = () => {
  const [isSpecialst, setIsSpecialst] = useState(false)
  const [isExpert, setIsExpert] = useState(false)
  const [isSelection, setIsSelection] = useState(false)

  const [firstModal, setFirstModal] = useState(false)
  const [secondModal, setSecondModal] = useState(false)
  const [thirdModal, setThirdModal] = useState(false)

  const specialistLi = [
    'Советы по выбору объявлений',
    'Неограниченное количество авто в течении дня ',
    'Перемещение на автомобиле эксперта',
    'Стоимость выгоднее чем при разовом выезде',
  ]
  const selectionLi = [
    'Сопровождение процесса покупки от А до Я',
    'Подбор лучшего варианта на рынке',
    'Мы берем на себя общение с продавцами',
    'Доступ к закрытым каналам объявлений',
    'Проверка CarFax, AutoDNA и др. отчетов для автомобилей ввезенных из-за рубежа.',
    'Контроль передачи денег и оформления документов',
    'Гарантия полной юридической чистоты подобранного автомобиля',
    'Гарантия 30 дней заявленного технического состояния авто',
    'Делаем всё, чтобы вы получили только позитивные эмоции от покупки автомобиля',
    'Профессионально и аргументировано торгуемся с продавцом для вашей выгоды',
  ]
  const expertLi = [
    'Анализ глубины ремонта, если таковой был, и его влияние на эксплуатацию автомобиля',
    'Считывание имеющихся ошибок',
    `Проверка агрегатов на подтеки и запотевания`,
    `Оценка работы двигателя и навесного оборудования`,
  ]

  const [mark, setMark] = useState('')
  const [budget, setBudget] = useState('')
  const [isSent, setIsSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const phoneRef = useRef()

  useEffect(() => {
    IMask(phoneRef.current, {
      mask: '+{38 (\\0}00) 000-00-00',
      lazy: false,
    })
  }, [phoneRef])

  const handleSubmit = e => {
    const phone = phoneRef.current.value
    e.preventDefault()
    if (!phone.includes('_') && mark.length > 2 && budget.length > 1) {
      setIsLoading(true)
      axios.post('/sendData', {
        mark,
        phone,
        budget,
      })
      setIsLoading(false)
      setIsSent(true)
    }
  }

  return (
    <>
      <section className="services_section" id="prices">
        <div className="container">
          <SectionTitle title="Услуги и стоимость" />
          <div className="services">
            <div className="service_item">
              <h3>Специалист на день</h3>
              <ul>
                {specialistLi.map((li, lindex) => (
                  <li key={lindex}>{li}</li>
                ))}
              </ul>
              {isSpecialst ? (
                <div className="price_wrapper">
                  <div className="price">150$</div>
                  <button
                    type="button"
                    className="order_modal"
                    onClick={() => setFirstModal(true)}
                    id="form5_price_spec"
                  >
                    Заказать специалиста на день
                  </button>
                </div>
              ) : (
                <div className="get_price_wrapper">
                  <button
                    type="button"
                    onClick={() => setIsSpecialst(true)}
                    className="get_price"
                    id="btn_price1"
                  >
                    УЗНАТЬ ЦЕНУ
                  </button>
                </div>
              )}
            </div>
            <div className="service_item">
              <h3>Подбор авто “Под ключ”</h3>
              <ul>
                {selectionLi.map((li, lindex) => (
                  <li key={lindex}>{li}</li>
                ))}
              </ul>
              {isSelection ? (
                <div className="price_wrapper">
                  <div className="price_selection">
                    <div className="price_selection_container">
                      <h4>Стоимость услуги зависит от цены авто:</h4>
                      <ul>
                        <li>
                          <span>до 10 000$</span>
                          <span>от 350$</span>
                        </li>
                        <li>
                          <span>10 000$ - 25 000$</span>
                          <span>500$</span>
                        </li>
                        <li>
                          <span>25 000$ - 45 000$</span>
                          <span>700$</span>
                        </li>
                        <li>
                          <span>Свыше 45 000$</span>
                          <span>800$</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="order_modal"
                    onClick={() => setThirdModal(true)}
                    id="form6_price_podbor"
                  >
                    Подобрать автомобиль
                  </button>
                </div>
              ) : (
                <div className="get_price_wrapper">
                  <button
                    type="button"
                    onClick={() => setIsSelection(true)}
                    className="get_price"
                    id="btn_price2"
                  >
                    УЗНАТЬ ЦЕНУ
                  </button>
                </div>
              )}
            </div>
            <div className="service_item">
              <h3>Экспертный осмотр одного авто</h3>
              <ul>
                {expertLi.map((li, lindex) => (
                  <li key={lindex}>{li}</li>
                ))}
              </ul>
              {isExpert ? (
                <div className="price_wrapper">
                  <div className="price">100$</div>
                  <button
                    type="button"
                    className="order_modal"
                    onClick={() => setSecondModal(true)}
                    id="form7_price_osmotr"
                  >
                    Заказать осмотр авто
                  </button>
                </div>
              ) : (
                <div className="get_price_wrapper">
                  <button
                    type="button"
                    onClick={() => setIsExpert(true)}
                    className="get_price"
                    id="btn_price3"
                  >
                    УЗНАТЬ ЦЕНУ
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <Modal
          modalIsOpen={firstModal}
          setModalIsOpen={setFirstModal}
          isForm
          buttonText="Отправить"
        >
          <ModalHeader
            title="Заказать специалиста на день"
            text="Оставьте ваши контактные данные и получите помощь профессионала на целый день!"
            maxWidth="405px"
            maxWidthText="347px"
            isGreenLine
          />
        </Modal>
        <Modal
          modalIsOpen={secondModal}
          setModalIsOpen={setSecondModal}
          isForm
          buttonText="Отправить"
        >
          <ModalHeader
            title="Заказать экспертный осмотр авто"
            text="Выбрали авто? Мы проверим его “От” и “До”"
            maxWidth="405px"
            maxWidthText="347px"
            isGreenLine
          />
        </Modal>
        <Modal
          modalIsOpen={thirdModal}
          setModalIsOpen={setThirdModal}
          isForm
          isMark
          buttonText="Отправить"
        >
          <ModalHeader
            title="Заказать подбор"
            text="Мы с вами свяжемся и проконсультируем вас по подбору интересующего автомобиля"
            maxWidthText="423px"
            isGreenLine
          />
        </Modal>
      </section>
      <div className="container">
        <div className="left_appointment">
          <img src={woman} className="woman" alt="" />
          <div
            className="left_appointment_container"
            style={{ justifyContent: isLoading && 'center' }}
          >
            {!isLoading && (
              <div>
                <h4>
                  {isSent
                    ? 'Первый шаг к авто мечты - сделан'
                    : 'Оставьте заявку'}
                </h4>
                <p>
                  {isSent
                    ? 'Мы свяжемся и проконсультируем вас по подбору интересующего автомобиля'
                    : 'В ближайшее время наши менеджеры с вами свяжутся'}
                </p>
              </div>
            )}
            {!isSent && !isLoading && (
              <form onSubmit={handleSubmit}>
                <div>
                  <input
                    type="text"
                    placeholder="Марка/модель"
                    value={mark}
                    onChange={e =>
                      setMark(e.target.value.replace(/[^a-zA-Zа-яА-Я0-9]/, ''))
                    }
                    className="mark"
                  />
                  <input
                    type="text"
                    placeholder="Ваш номер телефона"
                    required
                    className="phone"
                    ref={phoneRef}
                  />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Бюджет($)"
                    value={budget}
                    onChange={e => setBudget(e.target.value.replace(/\D/, ''))}
                    className="budget"
                  />
                  <button type="submit" id="form8_marka">
                    Подобрать авто
                  </button>
                </div>
              </form>
            )}
            {isLoading && (
              <Loader type="Puff" color="#fff" height={100} width={100} />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ServicesSection
