import React from 'react'
import '../styles/Advantages.scss'
import SectionTitle from './SectionTitle'
import clock from '../images/advantages/1.png'
import torg from '../images/advantages/2.png'
import smile from '../images/advantages/3.png'
import person from '../images/advantages/4.png'
import report from '../images/advantages/5.png'
import upvote from '../images/advantages/6.png'
import whiteCar from '../images/white-car.png'
import Form from './Form'
import Slider from 'react-slick'
import SampleArrow from './SampleArrow'

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SampleArrow clazz="adv_arrow" side="right" />,
  prevArrow: <SampleArrow clazz="adv_arrow" side="left" />,
}

const AdvantsgesSection = () => {
  const advantages = [
    {
      image: clock,
      title: 'Экономия времени',
      text: 'Подбор авто не займёт у вас больше 2-х часов. Вам нужно только время на общение с нашими специалистами',
      clazz: 'rotate_r',
    },
    {
      image: torg,
      title: 'Обоснованный торг',
      text: 'В 70% случаев потраченные на нас средства окупаются благодаря торгу',
      clazz: 'rotate_l',
    },
    {
      image: smile,
      title: 'Никаких изнурительных поездок',
      text: 'Опыт наших специалистов позволяет исключить неподходящие варианты еще до выезда на осмотр авто, что значительно экономит время и нервы клиентов',
      clazz: 'rotate_l',
    },
    {
      image: person,
      title: 'Надёжность на первом месте',
      text: 'Мы работаем только с частными продавцами, официальными дилерами и проверенными Досками Объявлений',
      clazz: 'rotate_r',
    },
    {
      image: report,
      title: 'Постоянный доступ к отчетам',
      text: 'Мы храним все отчеты о просмотренных нами авто, неограниченное количество времени.',
      clazz: 'rotate_r',
    },
    {
      image: upvote,
      title: 'Отличное авто для вас',
      text: 'Благодаря просмотру многих вариантов нашими квалифицированными специалистами, мы находим лучший автомобиль для вас',
      clazz: 'rotate_l',
    },
  ]
  return (
    <section id="advantages" className="advantages_section">
      <div className="container">
        <SectionTitle
          title="Почему стоит обратиться именно к нам"
          maxWidth="475px"
        />
        <div className="advantages">
          {advantages.map((item, index) => (
            <div
              className="advantage_item"
              key={Math.random().toString(36).substring(7)}
            >
              <div className={`advantage_image ${item.clazz}`}>
                <img src={item.image} alt="" />
              </div>
              <div className="advantage_info">
                <h4>{item.title}</h4>
                <p>{item.text}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="advantages_slider">
          <Slider {...settings}>
            {advantages.map((item, index) => (
              <div
                className="advantage_item"
                key={Math.random().toString(36).substring(7)}
              >
                <div className="advantage_image">
                  <img src={item.image} alt="" />
                </div>
                <div className="advantage_info">
                  <h4>{item.title}</h4>
                  <p>{item.text}</p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="green_separator">
        <div className="container">
          <img className="white_car" src={whiteCar} alt="" />
          <Form
            isName
            buttonColor="black"
            image="white"
            sentText="Первый шаг к авто мечты - сделан"
            loaderColor="#fff"
            buttonText="Консультация эксперта"
            buttonId="form2_consult_expert"
          />
        </div>
      </div>
    </section>
  )
}

export default AdvantsgesSection
