import React, { useState } from 'react'
import '../styles/Accordion.scss'
import plus from '../images/plus.png'
import angle from '../images/angle.png'

const Accordion = ({ faq, close }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const hanldeClick = (i) => {
    if (i === activeIndex) {
      setActiveIndex(100)
    } else {
      setActiveIndex(i)
    }
  }
  return (
    <div>
      {faq.map((item, index) => (
        <div
          className="faq_item"
          onClick={() => hanldeClick(index)}
          key={Math.random().toString(36).substring(7)}
        >
          <div className="faq_header">
            <div className="faq_container">
              <h2>{item.title}</h2>
              <div className="faq_closer">
                <img
                  src={close === 'plus' ? plus : angle}
                  alt=""
                  style={{
                    transform:
                      activeIndex === index
                        ? `rotate(${close === 'plus' ? 45 : 180}deg)`
                        : 'rotate(0deg)',
                  }}
                />
              </div>
            </div>
          </div>
          {activeIndex === index && (
            <div className="faq_container">
              {item.title === 'КАК ОПЛАТИТЬ?' ? (
                <ul>
                  Услуги можно оплатить несколькими способами:
                  <li>
                    1{')'} Оплата на расчетный счет компании, через интернет
                    банкинг
                  </li>
                  <li>2{')'} Оплата наличными в офисе.</li>
                </ul>
              ) : (
                <p>{item.text}</p>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default Accordion
