import React, { useState, useRef, useEffect } from 'react'
import '../styles/Modal.scss'
import close from '../images/close.png'
import ModalForm from './ModalForm'
import Loader from 'react-loader-spinner'
import sent from '../images/big-sent.png'
import axios from 'axios'
import blueCarMobile from '../images/blue-car-mobile.png'

const Modal = ({
  modalIsOpen,
  setModalIsOpen,
  isForm,
  children,
  isMark,
  buttonText,
}) => {
  const [modalTop, setModalTop] = useState(0)
  const [isSent, setIsSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const ref = useRef()
  useEffect(() => {
    if (modalIsOpen) {
      setModalTop(window.pageYOffset)
      document.querySelector('body').style.overflowY = 'hidden'
    }
  }, [modalIsOpen])

  const handleCloseModal = () => {
    setModalIsOpen(false)
    document.querySelector('body').style.overflowY = 'auto'
    setIsLoading(false)
    setIsSent(false)
  }

  const handleSubmit = (e, data) => {
    e.preventDefault()
    console.log(data)
    if (!data.phone.includes('_')) {
      setIsLoading(true)
      axios.post('/sendData', data)
      setIsLoading(false)
      setIsSent(true)
    }
  }
  return (
    <div
      className="modal"
      style={{
        display: modalIsOpen ? 'flex' : 'none',
        top: modalTop,
      }}
      ref={ref}
    >
      <div className="content">
        <div className="content_container">
          <img src={close} className="close" onClick={handleCloseModal} />
          {isForm ? (
            <>
              {!isSent && !isLoading && (
                <>
                  {children}
                  <ModalForm
                    buttonText={buttonText}
                    isMark={isMark}
                    handleSubmit={(e, data) => handleSubmit(e, data)}
                  />
                </>
              )}
              {isLoading && (
                <div className="modal_loader">
                  <Loader
                    type="Puff"
                    color="#3B9E3E"
                    height={100}
                    width={100}
                  />
                </div>
              )}
              {isSent && (
                <div className="modal_sent">
                  <img src={sent} alt="" />
                  <p>
                    Первый шаг
                    <br /> к автомобилю мечты - сделан
                  </p>
                </div>
              )}
              <img src={blueCarMobile} className="blue_car_mobile" alt="" />
            </>
          ) : (
            <>{children}</>
          )}
        </div>
      </div>
    </div>
  )
}

export default Modal
