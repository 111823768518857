import React from 'react'
import SectionTitle from './SectionTitle'
import '../styles/Contacts.scss'
import phone from '../images/contacts/phone.png'
import viber from '../images/contacts/viber.png'
import telegram from '../images/contacts/telegram.png'
import facebook from '../images/contacts/facebook.png'
import instagram from '../images/contacts/instagram.png'
import whatsapp from '../images/contacts/whatsapp.png'
import Form from './Form'

const ContactsSection = () => {
  const contacts = [
    {
      name: 'Номер телефона',
      img: phone,
      href: 'tel: +380972978181',
      id: 'telephone',
    },
    {
      name: 'Viber',
      img: viber,
      href: 'viber://chat?number=%2B380972978181',
      id: 'viber',
    },
    {
      name: 'Telegram',
      img: telegram,
      href: 'tg://resolve?domain=@salecar_ua',
      id: 'tg',
    },
    {
      name: 'Facebook',
      img: facebook,
      href: 'https://www.facebook.com/Sale-Car-109132131515894',
      id: 'fb',
    },
    {
      name: 'Instagram',
      img: instagram,
      href: 'https://instagram.com/salecar.ua?utm_medium=copy_link',
      id: 'inst',
    },
    {
      name: 'Whatsapp',
      img: whatsapp,
      href: 'https://api.whatsapp.com/send?phone=+380972978181',
      id: 'wa',
    },
  ]
  return (
    <section className="contacts_section" id="contacts">
      <div className="container">
        <SectionTitle title="КОНТАКТЫ" />
        <div className="contacts">
          {contacts.map(({ href, name, img, id }) => (
            <a
              className="contact_item"
              key={Math.random().toString(36).substring(7)}
              href="#"
              target="_blank"
              href={href}
              id={id}
              key={id}
            >
              <img src={img} alt="" />
              <p>{name}</p>
            </a>
          ))}
        </div>
      </div>
      <div className="green_separator">
        <div className="container">
          <div className="order_consult">Заказать подбор</div>
          <Form
            isName
            buttonColor="black"
            image="white"
            sentText="Первый шаг к авто мечты - сделан"
            loaderColor="#fff"
          />
        </div>
      </div>
    </section>
  )
}

export default ContactsSection
