import * as React from 'react'
import AdvantsgesSection from '../components/AdvantsgesSection'
import AutoSalonSection from '../components/AutoSalonSection'
import CarSearchSection from '../components/CarSearchSection'
import CheckSection from '../components/CheckSection'
import ClientsSection from '../components/ClientsSection'
import ContactsSection from '../components/ContactsSection'
import FaqSection from '../components/FaqSection'
import Footer from '../components/Footer'
import GuaranteesSection from '../components/GuaranteesSection'
import MainSection from '../components/MainSection'
import Seo from '../components/seo'
import ServicesSection from '../components/ServicesSection'
import StepsSection from '../components/StepsSection'
import '../styles/index.scss'

const navigation = [
  { name: 'Главная', to: 'main', id: 'nav_btn_main' },
  { name: 'Подбор авто с нами', to: 'about', id: 'nav_btn_selection' },
  { name: 'Услуги и цены', to: 'prices', id: 'nav_btn_prices' },
  { name: 'Отзывы', to: 'clients', id: 'nav_btn_reviews' },
  { name: 'FAQ', to: 'faq', id: 'nav_btn_faq' },
  { name: 'Контакты', to: 'contacts', id: 'nav_btn_contacts' },
]

const IndexPage = () => {
  return (
    <>
      <Seo title="SALECAR" />
      <MainSection navigation={navigation} />
      <CarSearchSection />
      <AdvantsgesSection />
      <GuaranteesSection />
      <AutoSalonSection />
      <CheckSection />
      <StepsSection />
      <ServicesSection />
      <ClientsSection />
      <FaqSection />
      <ContactsSection />
      <Footer navigation={navigation} />
    </>
  )
}

export default IndexPage
