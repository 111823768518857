import React, { useState, useRef, useEffect } from 'react'
import '../styles/AutoSalon.scss'
import SectionTitle from './SectionTitle'
import Loader from 'react-loader-spinner'
import sentWhite from '../images/sent-white.png'
import axios from 'axios'
import IMask from 'imask'

const AutoSalon = () => {
  const scams = [
    {
      title: 'Скрутка пробега',
      text: 'Зачастую большинство автомобилей в автосалонах с неоригинальным пробегом',
    },
    {
      title: 'Продажа автомобиля с юридическими проблемами',
      text: 'Кредитные, залоговые, двойники и "перебитые", с запретами и ограничением на постановку на учет',
    },
    {
      title: 'Замена подушек на обманки',
      text: 'После ДТП, чтобы не тратиться на подушки, автосалоны ставят обманки. Им не важна ваша безопасность',
    },
    {
      title: 'Жесткие кредитные условия',
      text: 'Скрытые платежи, комиссии и высокие процентные ставки, о которых вы узнаете уже после покупки',
    },
    {
      title: 'Обманывают с годом выпуска',
      text: 'Большинство автосалонов намеренно завышают год выпуска автомобиля, чтобы завлечь вас. поэтому, очень важно проверять документы до покупки',
    },
    {
      title: 'Восстановление после ДТП',
      text: 'Такие автомобили опасны для жизни! Их восстанавливают максимально недорого',
    },
  ]
  const [isSent, setIsSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const phoneRef = useRef()

  useEffect(() => {
    IMask(phoneRef.current, {
      mask: '+{38 (\\0}00) 000-00-00',
      lazy: false,
    })
  }, [phoneRef])

  const handleSubmit = e => {
    e.preventDefault()
    const phone = phoneRef.current.value
    if (!phone.includes('_')) {
      setIsLoading(true)
      axios.post('/sendData', {
        phone,
      })
      setIsLoading(false)
      setIsSent(true)
    }
  }

  return (
    <section className="scams_section">
      <div className="container">
        <SectionTitle
          title="Виды мошенничества на авто-площадках"
          maxWidth="410px"
        />
        <h3 className="scam_subtitle">Будьте осторожны!</h3>
        <div className="scams">
          {scams.map((item, index) => (
            <div
              className="scam_item"
              key={Math.random().toString(36).substring(7)}
            >
              <div className="scam_sign"></div>
              <h3>{item.title}</h3>
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="green_separator">
        <div className="container">
          {!isSent && !isLoading && (
            <>
              {!isLoading && (
                <div className="gs_description">
                  Чтобы не наткнуться на обман и чувствовать себя
                  <b> в безопасности</b> закажите подбор авто
                </div>
              )}

              <form onSubmit={handleSubmit} className="contact_form">
                <input
                  type="text"
                  placeholder="Номер телефона"
                  required
                  ref={phoneRef}
                />
                <button
                  type="submit"
                  style={{
                    background: '#1B1818',
                  }}
                  id="form4_safe_podbor"
                >
                  Заказать подбор авто
                </button>
              </form>
            </>
          )}
          {isLoading && (
            <div className="sent">
              <Loader
                type="Puff"
                color="#fff"
                height={100}
                width={100}
                className="loader"
              />
            </div>
          )}
          {isSent && (
            <div className="sent">
              <div className="sent_info">
                <img src={sentWhite} alt="" />
                <p>
                  <span className="green_text">
                    Теперь вы в безопасности и не наткнетесь на обман
                  </span>
                  В ближайшее время наши менеджеры с вами свяжутся
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default AutoSalon
