import React, { useState, useRef, useEffect } from 'react'
import '../styles/ModalForm.scss'
import IMask from 'imask'

const ModalForm = ({ isMark, handleSubmit, buttonText }) => {
  const [mark, setMark] = useState('')
  const [budget, setBudget] = useState('')
  const phoneRef = useRef()

  useEffect(() => {
    IMask(phoneRef.current, {
      mask: '+{38 (\\0}00) 000-00-00',
      lazy: false,
    })
  }, [phoneRef])

  return (
    <form
      className="modal_form"
      onSubmit={e =>
        handleSubmit(e, { mark, phone: phoneRef.current.value, budget })
      }
    >
      {isMark && (
        <input
          type="text"
          placeholder="Марка/модель"
          value={mark}
          onChange={e =>
            setMark(e.target.value.replace(/[^a-zA-Zа-яА-Я0-9]/, ''))
          }
        />
      )}
      <input
        type="text"
        placeholder="Ваш номер телефона*"
        ref={phoneRef}
        required
      />
      <input
        type="text"
        placeholder="Бюджет($)*"
        value={budget}
        onChange={e => setBudget(e.target.value.replace(/\D/, ''))}
        required
      />
      <button type="submit">{buttonText}</button>
    </form>
  )
}

export default ModalForm
