import React, { useState } from 'react'
import '../styles/Faq.scss'
import SectionTitle from './SectionTitle'
import switcher from '../images/faq-switcher.png'
import Accordion from './Accordion'

const FaqSection = () => {
  const faq = [
    {
      title: 'СКОЛЬКО ВРЕМЕНИ ЗАНИМАЕТ ПОДБОР АВТОМОБИЛЯ ПОД КЛЮЧ?',
      text: `Подбор автомобиля под ключ занимает, как правило, от 1 недели до 3 месяцев, в зависимости от сложности технического задания. В договоре прописан срок подбора длительностью 3 календарных месяца. Это сделано для того, чтобы обезопасить заказчика в случае, если на рынке происходят непредвиденные обстоятельства.`,
    },
    {
      title: 'КАК ОПЛАТИТЬ?',
    },
    {
      title: 'А ЧТО БУДЕТ, ЕСЛИ ВЫ НЕ НАЙДЕТЕ АВТОМОБИЛЬ ПО МОИМ ПАРАМЕТРАМ?',
      text: `Мы не берем в работу заказы, которые не соответствуют рынку (завышенные ожидания, по заниженной цене). В случае если на рынке Украины не будет подходящих для вас вариантов, мы можем привезти под заказ авто из США, Европы, Кореи и Китая - мы найдем тот автомобиль, который вы действительно полюбите.`,
    },
    {
      title:
        'ВЫЕЗДНАЯ ДИАГНОСТИКА АВТОМОБИЛЯ ПЕРЕД ПОКУПКОЙ, КАК ВСЕ ПРОИСХОДИТ?',
      text: `Перед выездной диагностикой мы подробно изучаем лучшие варианты на рынке и отсеиваем заведомо неподходящие автомобили. При выездной диагностике  наш эксперт проводит полный список работ: диагностику автомобиля (проверка кузова и лакокрасочного покрытия автомобиля, осмотр двигателя и лонжеронов, проверка пластика и резинок в салоне и под капотом, проверяет наличие подушек безопасности, исправность коробки передач, визуальный осмотр салона на соответствие заявленного  пробега, компьютерная диагностика блоков двигателя), юридическую проверку автомобиля и производит обоснованный торг с продавцом, если машина в отличном состоянии!`,
    },
  ]
  const [activeIndex, setActiveIndex] = useState(0)
  return (
    <section id="faq" className="faq_section">
      <div className="container">
        <SectionTitle title="qaf" />
        <div className="faq">
          <Accordion faq={faq} close="angle" />
        </div>
      </div>
    </section>
  )
}

export default FaqSection
