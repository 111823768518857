import React from 'react'
import '../styles/Header.scss'
import logo from '../images/logo.png'
import facebook from '../images/facebook.png'
import instagram from '../images/instagram.png'
import close from '../images/close.png'

const Header = ({ navigation }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const handleOpen = () => {
    setIsOpen(true)
    document.body.style.overflowY = 'hidden'
  }

  const handleClose = () => {
    setIsOpen(false)
    document.body.style.overflowY = 'auto'
  }
  return (
    <header>
      <div className="logo">
        <img src={logo} alt="" />
      </div>
      <nav>
        <ul>
          {navigation.map((item, index) => {
            return (
              <li
                key={Math.random().toString(36).substring(7)}
                className="nav_item"
              >
                <a href={`#${item.to}`}>{item.name}</a>
              </li>
            )
          })}
        </ul>
      </nav>
      <div className="social_media">
        <a
          href="https://www.facebook.com/Sale-Car-109132131515894"
          className="sm_wrapper"
          target="_blank"
          id="fb1"
        >
          <img src={facebook} alt="" />
        </a>
        <a
          href="https://instagram.com/salecar.ua?utm_medium=copy_link"
          className="sm_wrapper"
          target="_blank"
          id="inst1"
        >
          <img src={instagram} alt="" />
        </a>
      </div>
      <nav
        className="mob_nav"
        style={{ visibility: isOpen ? 'visible' : 'hidden' }}
      >
        <div className="wrapper">
          <img src={close} onClick={handleClose} alt="" />
          <ul>
            {navigation.map(({ name, to, id }, index) => {
              return (
                <li
                  key={Math.random().toString(36).substring(7)}
                  className="nav_item"
                >
                  <a onClick={handleClose} href={`#${to}`} id={id}>
                    {name}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      </nav>
      <div className="hamburger" onClick={handleOpen}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    </header>
  )
}

export default Header
