import React from 'react'
import '../styles/Footer.scss'
import logo from '../images/logo.png'
import mapSign from '../images/map-sign.png'
import mailSign from '../images/mail-sign.png'
import phoneSign from '../images/phone-sign.png'
import facebook from '../images/facebook.png'
import instagram from '../images/instagram.png'

const Footer = ({ navigation }) => {
  const contacts = [
    {
      img: mapSign,
      text: 'Украина, Киев, Коломыйский переулок 20',
      href: `https://www.google.com.ua/maps/place/Kolomyis'kyi+Ln,+20,+Kyiv,+02000/@50.393177,30.490171,17z/data=!3m1!4b1!4m5!3m4!1s0x40d4c8c56977cb85:0x51e320f5da862ca1!8m2!3d50.393177!4d30.4923597?hl=en-GB`,
    },
    {
      img: mailSign,
      text: 'salecar.auto@gmail.com',
      href: 'mailto:salecar.auto@gmail.com',
    },
    {
      img: phoneSign,
      text: '+ 38 (097) 297 8181',
      href: 'tel:+ 38 (097) 297 8181',
    },
  ]
  return (
    <footer>
      <div className="container">
        <div className="logo">
          <img src={logo} alt="" />
          <p>Copyrighting Ⓒ 2021 Salecar</p>
        </div>
        <nav>
          <ul className="footer_nav_ul">
            {navigation.map(({ name, to, id }, index) => (
              <li
                key={Math.random().toString(36).substring(7)}
                className="footer_nav_link"
              >
                <a href={`#${to}`} id={id}>
                  {name}
                </a>
              </li>
            ))}
          </ul>
        </nav>
        <ul className="footer_contacts">
          {contacts.map((item, index) => (
            <li
              className="contacts_item"
              key={Math.random().toString(36).substring(7)}
            >
              <img src={item.img} alt="" />
              <a href={item.href} target="_blank">
                {item.text}
              </a>
            </li>
          ))}
        </ul>
        <div className="social_media">
          <a
            href="https://www.facebook.com/Sale-Car-109132131515894"
            className="sm_wrapper"
            id="fb_end"
          >
            <img src={facebook} alt="" />
          </a>
          <a
            href="https://instagram.com/salecar.ua?utm_medium=copy_link"
            className="sm_wrapper"
            id="inst_end"
          >
            <img src={instagram} alt="" />
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer
