import React from 'react'
import '../styles/Check.scss'
import Slider from 'react-slick'
import leftArrow from '../images/left-white-arrow.png'
import rightArrow from '../images/right-white-arrow.png'
import blueCar from '../images/blue-car.png'
import video from '../images/main.mp4'
import preview from '../images/preview.png'
import SampleArrow from './SampleArrow'

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SampleArrow clazz="check_arrow" side="right" />,
  prevArrow: <SampleArrow clazz="check_arrow" side="left" />,
}

const CheckSection = () => {
  const checkItems = [
    {
      title: 'Двигатель',
      text: 'Проверка состояния двигателя и навесного оборудования, замер компрессии, проверка утечек технических жидкостей.',
    },
    {
      title: 'АКПП',
      text: 'Наши специалисты знают все тонкости работы АКПП, что позволяет им определить исправность агрегата.',
    },
    {
      title: 'Юр. чистоту',
      text: 'Криминалистическая экспертиза авто. Проверка на кредитные обязательства и т.д',
    },
    {
      title: 'Приборы',
      text: 'Проверка всех электронных блоков авто позволяет определить неисправности и несоответствие заявленного пробега.',
    },
    {
      title: 'Кузов',
      text: 'Проверка кузова на предмет перекраса, ремонта, снятия-установки элементов.',
    },
    {
      title: 'Колеса',
      text: 'Равномерность износа, дата производства, состояние и марка шин - важный момент при покупке автомобиля',
    },
  ]
  const checkItems2 = [
    {
      title: 'Амортизаторы',
      text: 'Это один из самых важных элементов подвески. Они напрямую влияют на управляемость автомобиля, на его устойчивость и плавность хода автомобиля.',
    },
    {
      title: 'Тормоза',
      text: 'Проверяем состояние тормозных механизмов, дисков, колодок, антиблокировочной, антипробуксовочной систем и антизаноса.',
    },
    {
      title: 'АКБ ',
      text: 'Проверка даты выпуска',
    },
    {
      title: 'Турбина',
      text: 'Мы отлеживаем различные параметры работы турбины, наличие масла, давление, электронику для оценки ее состояния.',
    },
    {
      title: 'Рулевое управление',
      text: 'Наличие люфтов, течей, стуков в рулевом управлении. Уровень рабочей жидкости, состояние рулевой рейки и работоспособность усилителя руля.',
    },
    {
      title: 'Впуск',
      text: 'Современные автомобили с нормами токсичности Евро 3 и выше, имеют дорогостоящие элементы в системе выхлопа, такие как нейтрализатор выхлопных газов',
    },
  ]
  return (
    <section className="check_section" id="about">
      <video loop muted autoPlay poster={preview}>
        <source src={video} type="video/mp4" />
      </video>
      <div className="container">
        <h2>Мы проверяем</h2>
        <div>
          <Slider {...settings}>
            <div className="check">
              {checkItems.map((item, index) => (
                <div
                  className="check_item"
                  key={Math.random().toString(36).substring(7)}
                >
                  <div>
                    <h3>{item.title}</h3>
                    <p>{item.text}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="check">
              {checkItems2.map((item, index) => (
                <div
                  className="check_item"
                  key={Math.random().toString(36).substring(7)}
                >
                  <div>
                    <h3>{item.title}</h3>
                    <p>{item.text}</p>
                  </div>
                </div>
              ))}
            </div>
          </Slider>
        </div>
      </div>
      <img src={blueCar} className="blueCar" alt="" />
    </section>
  )
}

export default CheckSection
