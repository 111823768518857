import React, { useState, useEffect, useRef } from 'react'
import '../styles/Form.scss'
import Loader from 'react-loader-spinner'
import sent from '../images/sent.png'
import sentWhite from '../images/sent-white.png'
import axios from 'axios'
import IMask from 'imask'

const Form = ({
  isName,
  buttonColor,
  sentText,
  image,
  loaderColor,
  buttonText,
  buttonId,
}) => {
  const [name, setName] = useState('')
  const [isSent, setIsSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const phoneRef = useRef()

  useEffect(() => {
    IMask(phoneRef.current, {
      mask: '+{38 (\\0}00) 000-00-00',
      lazy: false,
    })
  }, [phoneRef])

  const handleSend = () => {
    setIsLoading(true)
    axios.post('/sendData', {
      name,
      phone: phoneRef.current.value,
    })
    setIsLoading(false)
    setIsSent(true)
  }

  const handleSubmit = e => {
    e.preventDefault()
    const phone = phoneRef.current.value
    if (isName) {
      if (!phone.includes('_') && name.length > 2) {
        handleSend()
      }
    } else {
      if (!phone.includes('_')) {
        handleSend()
      }
    }
  }

  return (
    <>
      {!isSent && !isLoading && (
        <form onSubmit={handleSubmit} className="contact_form">
          <input
            type="text"
            placeholder="Ваше имя"
            style={{ display: isName ? 'block' : 'none' }}
            required
            value={name}
            onChange={e =>
              setName(e.target.value.replace(/[^a-zA-Zа-яА-Я]/g, ''))
            }
          />
          <input
            type="text"
            ref={phoneRef}
            placeholder="Номер телефона"
            required
          />

          <button
            id={buttonId}
            type="submit"
            style={{
              background: buttonColor === 'black' ? '#1B1818' : '#3B9E3E',
            }}
          >
            {buttonText || 'Заказать подбор'}
          </button>
        </form>
      )}
      {isLoading && (
        <div className="sent">
          <Loader
            type="Puff"
            color={loaderColor}
            height={100}
            width={100}
            className="loader"
          />
        </div>
      )}
      {isSent && (
        <div className="sent">
          <div className="sent_info">
            <img src={image === 'green' ? sent : sentWhite} alt="" />
            <p>
              <span className="green_text">{sentText}</span>В ближайшее время
              наши менеджеры с вами свяжутся
            </p>
          </div>
        </div>
      )}
    </>
  )
}

export default Form
