import React, { useState, useEffect } from 'react'
import SectionTitle from './SectionTitle'
import '../styles/Clients.scss'
import smallClient1 from '../images/clients/small/1.jpg'
import smallClient2 from '../images/clients/small/2.jpg'
import smallClient3 from '../images/clients/small/3.jpg'
import smallClient4 from '../images/clients/small/4.jpg'
import smallClient5 from '../images/clients/small/5.jpg'
import smallClient6 from '../images/clients/small/6.jpg'
import smallClient7 from '../images/clients/small/7.jpg'
import smallClient8 from '../images/clients/small/8.jpg'
import smallClient9 from '../images/clients/small/9.jpg'
import smallClient10 from '../images/clients/small/10.jpg'
import smallClient11 from '../images/clients/small/11.jpg'
import smallClient12 from '../images/clients/small/12.jpg'

import bigClient1 from '../images/clients/big/1.jpg'
import bigClient2 from '../images/clients/big/2.jpg'
import bigClient3 from '../images/clients/big/3.jpg'
import bigClient4 from '../images/clients/big/4.jpg'
import bigClient5 from '../images/clients/big/5.jpg'
import bigClient6 from '../images/clients/big/6.jpg'
import bigClient7 from '../images/clients/big/7.jpg'
import bigClient8 from '../images/clients/big/8.jpg'
import bigClient9 from '../images/clients/big/9.jpg'
import bigClient10 from '../images/clients/big/10.jpg'
import bigClient11 from '../images/clients/big/11.jpg'
import bigClient12 from '../images/clients/big/12.jpg'
import Slider from 'react-slick'
import SampleArrow from './SampleArrow'
import quotes from '../images/quotes.png'

const settings = {
  dots: true,
  infinite: true,
  speed: 400,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SampleArrow clazz="client_arrow" side="right" />,
  prevArrow: <SampleArrow clazz="client_arrow" side="left" />,
}

const ClientsSection = () => {
  const [clientsState, setClientsState] = useState()
  const [reviewsState, setReviewsState] = useState()
  const clients = [
    [
      {
        image: smallClient1,
        model: 'Volkswagen Beetle',
        deliveryDays: '16 дней',
        sum: '700$',
      },
      {
        image: smallClient2,
        model: 'Kia Soul',
        deliveryDays: '7 дней',
        sum: '500$',
      },
      {
        image: smallClient3,
        model: 'Subaru Forester',
        deliveryDays: '14 дней',
        sum: '950$',
      },
    ],
    [
      {
        image: smallClient4,
        model: 'Ford Escape/Kuga',
        deliveryDays: '24 дня',
        sum: '900$',
      },
      {
        image: smallClient5,
        model: 'Renault Megane',
        deliveryDays: '5 дней',
        sum: '500$',
      },
      {
        image: smallClient6,
        model: 'Subaru Forester',
        deliveryDays: '14 дней',
        sum: '800$',
      },
    ],
    [
      {
        image: smallClient7,
        model: 'BMW 328',
        deliveryDays: '25 дней',
        sum: '1000$',
      },
      {
        image: smallClient8,
        model: 'Honda Civic',
        deliveryDays: '7 дней',
        sum: '800$',
      },
      {
        image: smallClient9,
        model: 'Honda Civic',
        deliveryDays: '10 дней',
        sum: '500$',
      },
    ],
    [
      {
        image: smallClient10,
        model: 'Audi',
        deliveryDays: '16 дней',
        sum: '1000$',
      },
      {
        image: smallClient11,
        model: 'Lexus IS300',
        deliveryDays: '21 день',
        sum: '1000$',
      },
      {
        image: smallClient12,
        model: 'Skoda',
        deliveryDays: '10 дней',
        sum: '600$',
      },
    ],
  ]

  const reviews = [
    [
      {
        image: bigClient1,
        text: `Загорелся идеей покупки Lexus и после нескольких поездок на просмотр – понял, что сам не куплю ) Выбор пал на этих ребят в первую очередь из-за компетентности менеджера, потом когда дело дошло до ознакомления с договором – сомнений не было, все понятно написано и без «мелкого шрифта».  Дальше подборы от менеджеров, рассмотрение того что находил я сам, но на деле было откровенным г*** и спустя 21 день – вот она! Все проверили, залезли ну просто везде))) Чистая машина от адекватного продавца (спасибо ему, все рассказал как есть и это было еще одним плюсом к покупке именно этой машины)! Ребят рекомендую, буду и дальше обращаться!`,
        name: 'Андрей',
      },
      {
        image: bigClient2,
        name: 'Александр',
        text: `Хотим поблагодарить компанию за помощь в вопросе подбора авто. Это был первый наш опыт покупки машины, искали сами, но поняли что нужно обращаться к специалистам (так как на рынке очень много подводных камней), также боялись и «аферистов», но тут компания честная и отношение к клиентам отличное. Машины не смотрят все подряд, выбирают четко по вашим требованиям. Очень терпеливые, всё объясняют, если вы что-то не знаете. Осмотр машин детальный, смотрят всё и везде до мелочей, что очень радует. Если чего-то не знаете вам расскажут. Спасибо большое 🙏  за работу по выбору авто. Мы остались довольны.`,
      },
    ],
    [
      {
        image: bigClient3,
        name: 'Анастасия',
        text: 'Хочу выразить огромную благодарность специалистам SaleCar за выбор автомобиля! Я очень долго не могла найти подходящую модель автомобиля, стоило только обратиться к ребятам в компанию и сразу попали в точку. Я не ожидала такого быстрого результата. Насколько профессионально подобрали, осмотрели, подсказали, помогли решить все вопросы. Все что от меня потребовалось это просматривать варианты ( которые присылали) и соглашаться или не соглашаться , времени затраченного мной на этот вопрос – максимум 1,5-2 часа, всего на всего!! Ребята это просто профессионалы с большой буквы. Они помогают во всех моментах! Очень вам благодарна и буду рекомендовать всем знакомым)',
      },
      {
        image: bigClient4,
        name: 'Владимир',
        text: 'Зібрався купувати Kia Soul і вирішив звернутися до професіоналів. Як і очікувалось, вони дуже гарні хлопці. Зустрівся, домовився і мені одразу допомогли. Професійно оглянули автомобіль, розповіли всі деталі по авто, все показали як воно є. І я прийняв рішення придбати цю автівку . Спасибі хлопцям за допомогу! Вони не тільки гарні професіонали, а й чудові люди!',
      },
    ],
    [
      {
        image: bigClient5,
        name: 'Валерия',
        text: `Компания SaleCar проделала отличную работу по подбору авто! Что понравилось:
        - Специалисты были на связи ежедневно быстро реагировали на все моменты
        - всегда учитывали пожелания 
        - дали мне принять решение о покупке самостоятельно, без давления
        - полностью позаботились о сделке вплоть до получения тех. паспорта и номеров 
        - да и просто общение в чате было крайне приятным))
        
        Спасибо вам)`,
      },
      {
        image: bigClient6,
        name: 'София',
        text: 'Обратилась в компанию SaleCar за подбором желанного Форд Эскейп и ровно через 24 дня уехала на своем авто. Ребята очень быстро и профессионально подобрали мне подходящий по всем критериям автомобиль! Находили варианты, а также изучали варианты которые я присылала сама, проверяли их по всем базам, ездили на экспертный осмотр, а так же оставляли подробный и развернутый отзыв. На последок помогли с оформлением и дали кучу полезных советов. До сих пор помогают, когда возникают вопросы по обслуживанию и пр. Я осталась очень довольна и всем рекомендую!',
      },
    ],
    [
      {
        image: bigClient7,
        name: 'Игорь',
        text: 'Изначально хотели сами купить авто, казалось бы что тут сложного? При покупке авто продавцы много раз пытались нас обмануть (рассказывали что машины не биты, не утопленные, но это не правда). Хорошо, что мы не соглашались сразу и после просмотров изучали машины по винкоду, после чего решили не рисковать все же и работать со специалистами. Благодарны за проделанную работу, за терпение и полное сопровождение до последней минуты. Очень рады что обратились именно в вашу компанию, так как при подборе были учтены все наши желания и даже больше. При выборе фирмы, которая занималась бы автоподбором, прочитали многое и просмотрели все варианты. Но выбор сделали правильный, так как в других фирмах много ограничений как по проверкам на сто так и по самим вариантам. Могу рекомендовать на все 100%',
      },
      {
        image: bigClient8,
        name: 'Марина',
        text: 'Обратились в SaleCar за подбором машинки. Уже на следующий день встретились со специалистом компании для личного знакомства и подписания договора. Специалисты отлично знают свою роботу / рынок - сориентируют, какие марки / модели можно смотреть под определенный бюджет. Очень помогли с выбором моделей и список авто сократился в разы. Проводят тщательный первичный осмотр, если все хорошо - проводят еще более детальный осмотр уже на Сто. Быстрое оформление в МРЕО. Сервис отличный!',
      },
    ],
    [
      {
        image: bigClient9,
        name: 'Дмитрий',
        text: 'Ребят, оказывается покупать машину – это легко! Раньше всегда проходил через стресс, пока найдешь, пока все посмотришь, на словах одно, на деле – другое… Уйма времени уходила! Решил попробовать эту новую и модную услугу ))) И остался доволен, ты просто утверждаешь заинтересовавшие тебя авто, ребята все делают за тебя и исходя из их рекомендаций, проверок, отчетов! А ты уже выбираешь какое авто покупать! Менеджер становится твоим лучшим другом, все сотрудники в процессе участвуют как для себя! Видно, что команда горит этим делом! Желаю Вам не утратить запал и становится только лучше! ОГРОМНОЕ СПАСИБО!',
      },
      {
        image: bigClient10,
        name: 'Сергей',
        text: 'Покупаю автомобиль через ребят уже во второй раз и точно не последний!Помимо стандартной помощи в подборе ребята всегда помогут советом, подскажут особенности эксплуатации той или иной модели и предложат альтернативы для покупки. Однозначно рекомендую к сотрудничеству 👍',
      },
    ],
    [
      {
        image: bigClient11,
        name: 'Сергей',
        text: 'Хочу подякувати команії Salecar за підібраний автомобіль Subaru Forester.З вернувся в компанію за підбором Audi Allroad як пріоритетним варіантом, а Subaru як запасний варіант. Мене одразу зорієнтувати що з Allroad буде не просто і бюджет на адекватний варіант буде значно вище, але вирішили дивитись обидва варіанти. Після декількох оглянутих Audi вирішили сфокусуватись на Субару, так як хорошого варіанту за мій бюджет не було на ринку, а ті які доходили до огляду були ну дуже погані.З початку роботи до моменту купівлі пройшло приблизно 14 днів, був підібран і куплений Subaru Forester в гарному стані і комплектації. Спеціаліст ретельно перевіряв кожне авто, надсилав звіти, фото та відео. Після кожного огляду зідзвонювались і проговорювали все по перевірці авто. Автомобілем повністю задоволений і щиро вдячний за виконану роботу',
      },
      {
        image: bigClient12,
        name: 'Максим',
        text: 'Максимально удобный сервис! Нашел машину в другом городе, позвонил в компанию и заказал проверку, получил все отчеты, все описание, фото/видео ну просто тонну информации! Уже полностью понимающий все по машине приезжаю в Киев, встречаюсь с менеджером, едем и забираем машину! Все прошло просто, легко и спокойно! Отрабатывают свои деньги на все 100% Спасибо спецам – машиной очень доволен!',
      },
    ],
  ]

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      document.documentElement.clientWidth < 991
    ) {
      const res = []
      const res2 = []
      for (const i of clients) {
        for (const k of i) {
          res.push([k])
        }
      }
      for (const r of reviews) {
        for (const j of r) {
          res2.push([j])
        }
      }
      setClientsState(res)
      setReviewsState(res2)
    } else {
      setClientsState(clients)
      setReviewsState(reviews)
    }
  }, [])
  return (
    <section className="clients_section" id="clients">
      <div className="container">
        <SectionTitle title="Наши клиенты" />
        <h3 className="clients_subtitle">фото</h3>
        <div
          className="clients"
          // onPointerDown={() => (document.body.style.overflowY = 'hidden')}
        >
          <Slider
            {...settings}
            // afterChange={() => (document.body.style.overflowY = 'auto')}
          >
            {clientsState &&
              clientsState.map((item, index) => (
                <div
                  className="clients_wrapper"
                  key={Math.random().toString(36).substring(7)}
                >
                  {item.map((clientItem, cindex) => (
                    <div className="client_item" key={cindex}>
                      <div className="client_image">
                        <img src={clientItem.image} alt="" />
                      </div>
                      <h3>{clientItem.model}</h3>
                      <ul className="buying_info">
                        <li>
                          <span>Время подбора:</span>
                          <span className="green">
                            {clientItem.deliveryDays}
                          </span>
                        </li>
                        <li>
                          <span>Сумма торга:</span>
                          <span className="green">{clientItem.sum}</span>
                        </li>
                      </ul>
                    </div>
                  ))}
                </div>
              ))}
          </Slider>
        </div>
        <h3 className="clients_subtitle reviews_sub">Отзывы</h3>
        <div className="reviews">
          <Slider {...settings}>
            {reviewsState &&
              reviewsState.map((item, index) => (
                <div
                  className="review_wrapper"
                  key={Math.random().toString(36).substring(7)}
                >
                  {item.map((reviewItem, rindex) => (
                    <div className="review_item" key={rindex}>
                      <div className="review_item_container">
                        <img src={reviewItem.image} alt="" />
                        <div className="name">{reviewItem.name}</div>
                        <p>{reviewItem.text}</p>
                      </div>
                      <img src={quotes} className="quotes" alt="" />
                    </div>
                  ))}
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </section>
  )
}

export default ClientsSection
