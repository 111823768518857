import React, { useState } from 'react'
import '../styles/Guarantees.scss'
import SectionTitle from './SectionTitle'
import guar1 from '../images/guarantees/1.png'
import guar2 from '../images/guarantees/2.png'
import guar3 from '../images/guarantees/3.png'
import Modal from './Modal'
import ModalHeader from './ModalHeader'

const GuaranteesSection = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const guarantees = [
    {
      image: guar1,
      title: 'Гарантия юридической чистоты',
      text: 'Вы приобретаете автомобиль без запретов, а также без залоговых и кредитных обязательств',
    },
    {
      image: guar2,
      title: '30 дней Технической гарантии ',
      text: 'Мы гарантируем исправность двигателя, коробки и т.д., в течении месяца. Поскольку наши специалисты доскональнопроверяют авто “от и до”',
    },
    {
      image: guar3,
      title: 'Абсолютная прозрачность',
      text: 'Вы будете получать ежедневные отчеты о проделанной работе. Мы подберем лучшее предложение по Вашим критериям',
    },
  ]
  return (
    <section className="guarantees_section">
      <div className="container">
        <SectionTitle title="3 вида гарантии" />
        <div className="guarantees">
          {guarantees.map((item, index) => (
            <div
              className="guarantee_item"
              key={Math.random().toString(36).substring(7)}
            >
              <div className="guarantee_container">
                <img src={item.image} alt="" />
                <h3>{item.title}</h3>
                <p>{item.text}</p>
              </div>
            </div>
          ))}
        </div>
        <button
          type="button"
          onClick={() => setModalIsOpen(true)}
          id="form3_podbor"
        >
          Заказать подбор
        </button>
      </div>
      <Modal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
        isForm
        isMark
        buttonText="Подобрать авто"
      >
        <ModalHeader
          title="Заказать подбор"
          text="Мы с вами свяжемся и проконсультируем вас по подбору интересующего автомобиля"
          isGreenLine={false}
          maxWidthText="423px"
        />
      </Modal>
    </section>
  )
}

export default GuaranteesSection
