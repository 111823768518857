import React, { useState } from 'react'
import '../styles/Steps.scss'
import SectionTitle from './SectionTitle'
import Modal from './Modal'

const StepsSection = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const steps = [
    {
      svgDesktop: (
        <svg
          width="434"
          height="163"
          viewBox="0 0 434 163"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 20C0 8.9543 8.9543 0 20 0H382.39C389.925 0 396.82 4.23468 400.228 10.9548L431.413 72.4549C434.296 78.1404 434.296 84.8596 431.413 90.5452L400.228 152.045C396.82 158.765 389.925 163 382.39 163H20C8.9543 163 0 154.046 0 143V20Z"
            fill="#3B9E3E"
          />
        </svg>
      ),
      svgMobile: (
        <svg
          width="280"
          height="116"
          viewBox="0 0 280 116"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 15C0 6.71573 6.71573 0 15 0H265C273.284 0 280 6.71573 280 15V47.7055V82.9906C280 90.4553 274.511 96.7841 267.121 97.8398L142.121 115.697C140.714 115.898 139.286 115.898 137.879 115.697L12.8787 97.8398C5.48894 96.7841 0 90.4553 0 82.9906V15Z"
            fill="#3B9E3E"
          />
        </svg>
      ),
      title: 'Решили купить авто',
      text: 'Изучаете рынок, понимаете что нужна помощь профессионала',
    },
    {
      svgDesktop: (
        <svg
          width="428"
          height="163"
          viewBox="0 0 428 163"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.3641 14.5226C-2.00908 7.87041 2.82446 0 10.283 0H376.39C383.925 0 390.82 4.23468 394.228 10.9548L425.413 72.4549C428.296 78.1404 428.296 84.8596 425.413 90.5452L394.228 152.045C390.82 158.765 383.925 163 376.39 163H10.283C2.82447 163 -2.00907 155.13 1.36411 148.477L30.7404 90.5451C33.6234 84.8596 33.6234 78.1404 30.7404 72.4548L1.3641 14.5226Z"
            fill="#3B9E3E"
          />
        </svg>
      ),
      svgMobile: (
        <svg
          width="280"
          height="164"
          viewBox="0 0 280 164"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 15.2952C0 6.16691 8.08482 -0.845025 17.1213 0.445903L137.879 17.697C139.286 17.898 140.714 17.898 142.121 17.697L262.879 0.445903C271.915 -0.845025 280 6.1669 280 15.2951V45.7055V130.991C280 138.455 274.511 144.784 267.121 145.84L142.121 163.697C140.714 163.898 139.286 163.898 137.879 163.697L12.8787 145.84C5.48894 144.784 0 138.455 0 130.991V15.2952Z"
            fill="#3B9E3E"
          />
        </svg>
      ),
      title: 'Обратились в SALECAR ',
      text: 'Получаете консультацию и заключаете договор. В чате с личным менеджером получаете всю информацию по автомобилям',
    },
    {
      svgDesktop: (
        <svg
          width="389"
          height="163"
          viewBox="0 0 389 163"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.37173 14.5256C-2.00433 7.87323 2.8291 0 10.2891 0H369C380.046 0 389 8.95431 389 20V81.5V143C389 154.046 380.046 163 369 163H10.2891C2.8291 163 -2.00434 155.127 1.37172 148.474L30.7678 90.5511C33.6549 84.8624 33.6549 78.1376 30.7678 72.4489L1.37173 14.5256Z"
            fill="#3B9E3E"
          />
        </svg>
      ),
      svgMobile: (
        <svg
          width="280"
          height="164"
          viewBox="0 0 280 164"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 149C0 157.284 6.71573 164 15 164H265C273.284 164 280 157.284 280 149V46.2945V15.2952C280 6.16692 271.915 -0.845032 262.879 0.445908L142.121 17.697C140.714 17.898 139.286 17.898 137.879 17.697L17.1213 0.445908C8.08482 -0.845032 0 6.1669 0 15.2952V149Z"
            fill="#3B9E3E"
          />
        </svg>
      ),
      title: 'Получили проверенный автомобиль в своем бюджете',
      text: 'Выезд с вами на просмотр выбранного авто, контрольная проверка и покупка автомобиля',
    },
  ]

  return (
    <section className="steps_section">
      <div className="container">
        <SectionTitle title="Этапы работы" />
        <div className="steps">
          {steps.map((item, index) => (
            <div
              className="step_item"
              key={Math.random().toString(36).substring(7)}
            >
              {typeof window !== 'undefined' && window.innerWidth > 990 && (
                <>{item.svgDesktop}</>
              )}
              {typeof window !== 'undefined' && window.innerWidth <= 990 && (
                <>{item.svgMobile}</>
              )}
              <div className="step_info">
                <h3>{item.title}</h3>
                <p>{item.text}</p>
              </div>
              <span>0{index + 1}</span>
            </div>
          ))}
        </div>
        <button
          type="button"
          className="steps_more-info"
          onClick={() => setModalIsOpen(true)}
          id="btn_steps"
        >
          Подробнее
        </button>
      </div>
      <Modal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen}>
        <div className="modal_steps_wrapper">
          <h2>Этапы работы</h2>
          <ol>
            <li>
              Мы подбираем объявления, соответствующие требованиям заказчика,
              определяем варианты для осмотра.
              <br />
              <em>
                (Этот этап консультационный, он бесплатный и основной его целью
                является проанализировать, совпадает ли желания клиента с его
                бюджетом. )
              </em>{' '}
            </li>
            <li>Создаем Telegram чат с клиентом и начинается работа.</li>
            <li>
              <ul>
                Получив VIN- код, отправляем запрос на дилерский центр марки.
                Если машина обслуживалась, или хотя бы несколько раз заезжала на
                официальный сервис, узнаём:
                <li>На каком пробеге</li>
                <li>Какие работы выполнялись</li>
                <li>Количество владельцев</li>
                <li>ДТП</li>
                <li>Аресты</li>
                <li>Проверяем автомобиль по базе Полиции.</li>
              </ul>
            </li>
            <li>
              <ul>
                Если все чисто, осуществляем оперативный выезд:
                <li> Осматриваем автомобиль</li>
                <li>Проверяем лакокрасочное покрытие толщиномером</li>
                <li>Читаем ошибки</li>
                <li>
                  Проверяем весь пластик: резинки, ремни, подушки безопасности и
                  остальные важные моменты!
                </li>
                <li>Аресты</li>
                <li>Делаем видео и фотоотчет всех скрытых нюансов.</li>
              </ul>

              <em className="last_em">
                Это - первичный выезд эксперта. В случае, если автомобиль прошел
                нашу проверку, далее рекомендуем заказчику технический осмотр на
                СТО.
              </em>
            </li>
            <li>
              <ul>
                Технический осмотр на СТО:
                <li>Сопровождаем диагностику</li>
                <li>Записываем видео</li>
                <li>Показываем заключение сервиса.</li>
              </ul>
            </li>
            <li>
              На этом этапе, как правило, всего два пути: - автомобиль
              интересен, торгуемся, оформляем, или же ищем дальше - машина не
              прошла проверку на сервисе.
            </li>
          </ol>
          <div className="important">
            <div className="important_wrapper">
              <h2>ВАЖНО!</h2>
              <p>
                Мы не пропустим такие моменты как: неприятный запах в салоне,
                курили в машине или нет, возили животных, была ли утоплена ранее
                и прочие нюансы, что непременно важны для будущего владельца.
              </p>
              <p>
                Во время диагностики автомобиля на сервисе, эксперты записывают
                на видео весь процесс и мнение мастера приемщика о состоянии
                автомобиля, чтобы у Вас было несколько независимых мнений для
                принятия решения!
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </section>
  )
}

export default StepsSection
